<template>
  <div class="card article">
    <div class="card-body">
      <div class="card-title">
        <a :href="article.url" class="text-dark text-decoration-none fw-bold"><div v-html="article.title"></div></a>
      </div>
      <div>
        <small class="text-muted">{{ displayDatetime(article.published) }}</small>
        <small class="text-muted float-end">{{ article.publisher }}</small>
      </div>
    </div>
  </div>
</template>

<script>
let moment = require("moment");

export default {
  name: "Article",
  props: {
    article: Object
  },
  methods: {
    getImgUrl(source) {
      let images = require.context('../assets/sources/', false, /\.png$/)
      return images('./' + source + ".png")
    },
    displayDatetime(datetime) {
      return moment(datetime).format('DD.MM.YYYY');
    }

  }
}
</script>

<style scoped>
  .article {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  }
 .card-title {
   font-size: 15px;
 }

</style>