import { createRouter, createWebHistory } from 'vue-router'
import ElectionCampaignChronic from '../views/ElectionCampaignChronic.vue'
import BlogArticle from '../views/BlogArticle.vue'
import Imprint from '../views/Imprint.vue'
import DataProtectionPolicy from '../views/DataProtectionPolicy.vue'
import EventOverview from '../views/EventOverview.vue'
import NotFound from '../views/NotFound.vue'
import events from "../assets/events.json"

function getEvent (route) {
    let event = events.find(function (e) {
        return e.id === route.params.id;
    });
    return {
        event: event
    }
}

let path_404 = {
    path: '/404',
    name: 'NotFound',
    component: NotFound
};

const routes = [
    path_404,
    {
        path: '/',
        name: 'chronic',
        component: ElectionCampaignChronic
    },
    {
        path: '/:catchAll(.*)', redirect:'404'
    },
    {
        path: '/about',
        name: 'about',
        component: BlogArticle
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: Imprint
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: DataProtectionPolicy
    },
    {
        path: '/:id',
        name: 'event',
        component: EventOverview,
        props: getEvent,
        beforeEnter: (route, from, next) => {
            let event = events.find(function (e) {
                return e.id === route.params.id;
            });
            if (!event) next(path_404.path);
            else next();
        }
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash }
        }
    }
})
export default router