import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faInfoCircle,
    faChevronRight,
    faChevronLeft,
    faEnvelope,
    faDownload,
    faShare
} from '@fortawesome/free-solid-svg-icons'
import {faTwitter, faGithub, faLinkedin, faReddit, faTelegram, faFacebook, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueSocialSharing from 'vue-social-sharing'

library.add(faInfoCircle, faTwitter, faGithub, faChevronRight, faChevronLeft, faEnvelope, faLinkedin,
    faDownload, faShare, faReddit, faTelegram, faWhatsapp, faFacebook)

import {createApp} from 'vue'
import App from './App.vue'
import router from "./router/index.js"

const app = createApp(App);
app.use(router)
app.use(VueSocialSharing)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')


