<template>
  <div id="wrap">
    <div id="main" class="clear-top">
      <nav class="navbar navbar-light bg-light">
        <div class="container">
          <router-link :to="{name: 'chronic'}" class="text-decoration-none navbar-brand">
            <img src="./assets/logo.png" alt="" width="37" height="37"
                 class="d-inline-block">
            <span class="">PoliTrack</span>
          </router-link>
          <div style="display: inline">
            <router-link :to="{name: 'about'}" class="text-decoration-none text-dark">
              <font-awesome-icon class="nav-logo" :icon="['fas', 'info-circle']"/>
            </router-link>
            <a href="https://twitter.com/pltrck" class="text-decoration-none text-dark">
              <font-awesome-icon class="nav-logo" :icon="['fab', 'twitter']"/>
            </a>
            <a href="https://github.com/politrack" class="text-decoration-none text-dark">
              <font-awesome-icon class="nav-logo" :icon="['fab', 'github']"/>
            </a>
            <a class="dropdown">
              <span id="dropdownMenuLink" data-bs-toggle="dropdown"
                    aria-expanded="false" class="nav-logo">
                <font-awesome-icon class="nav-logo" :icon="['fas', 'share']"/>
              </span>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink"
                   style="margin-top: 22px; margin-right: 20px">
                <ShareNetwork
                    v-for="network in networks" :key="network.id"
                    class="shareNetwork dropdown-item"
                    :network="network.id"
                    url="https://politrack.org/"
                    title="PoliTrack - Die Themen des Wahlkampfes"
                    description="Mithilfe von künstlicher Intelligenz hat PoliTrack Artikel über politisch relevante Ereignisse nach Themen gruppiert und diese zeitlich mit den Umfragewerten zur Bundestagswahl verknüpft."
                    twitter-user="pltrck"
                    media="https://politrack.org/img/share/preview.png"
                    :popup="{'width': '800px', 'height': '800px'}"
                    hashtags="btw21,politrack">
                  <font-awesome-icon class="nav-logo" :icon="[network.iconType, network.icon]"/>
                  {{ network.name }}
                </ShareNetwork>
              </div>
            </a>
          </div>
        </div>
      </nav>

      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>

  <footer class="bg-light shadow-sm">
    <div class="container">
      <div class="row">
        <div class="col-12 text-muted small">
          <img src="./assets/logo.png" alt="" width="24" height="24"
               class="d-inline-block">
          <span class="ms-2">PoliTrack</span>
          &middot;
          <router-link :to="{name: 'imprint'}" class="text-decoration-none">Impressum</router-link>
          &middot;
          <router-link :to="{name: 'privacy'}" class="text-decoration-none">Datenschutzerklärung</router-link>
          &middot;
          <a href="https://drive.google.com/drive/folders/1eELowuAKyvr7VnQo91Ak6EwLhqB5ShM7?usp=sharing"
             class="text-decoration-none">Presse</a>
          &middot;
          <router-link :to="{name: 'about'}" class="text-decoration-none">Über Uns</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'App',
  data: function () {
    return {
      networks: [
        {
          'name': 'Twitter',
          'id': 'Twitter',
          'icon': 'twitter',
          'iconType': 'fab'
        },
        {
          'name': 'WhatsApp',
          'id': 'WhatsApp',
          'icon': 'whatsapp',
          'iconType': 'fab'
        },
        {
          'name': 'Telegram',
          'id': 'Telegram',
          'icon': 'telegram',
          'iconType': 'fab'
        },
        {
          'name': 'Reddit',
          'id': 'Reddit',
          'icon': 'reddit',
          'iconType': 'fab'
        },
        {
          'name': 'Facebook',
          'id': 'facebook',
          'icon': 'facebook',
          'iconType': 'fab'
        },
        {
          'name': 'Email',
          'id': 'email',
          'icon': 'envelope',
          'iconType': 'fas'
        },
        {
          'name': 'LinkedIn',
          'id': 'linkedin',
          'icon': 'linkedin',
          'iconType': 'fab'
        }
      ]
    }
  },
  computed: {
    showShare: function () {
      return this.$route.name === 'chronic'
    }
  }
}
</script>

<style>
.nav-link {
  display: inline;
  margin: 0;
  padding: 0;
  color: #adb5bd;
}

.nav-link.active {
  color: #0d6efd;
}

.nav-logo {
  font-size: 20px;
  margin-right: 14px;
  color: black;
}

#wrap {
  min-height: 100%;
}

#main {
  overflow: auto;
  padding-bottom: 80px;
}

footer {
  position: relative;
  margin-top: -80px;
  height: 80px;
  padding-top: 2em;
  padding-bottom: 2em;
  clear: both;
}

.shareNetwork {
  text-decoration: none;
  color: black;
}
</style>
